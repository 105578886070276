// extracted by mini-css-extract-plugin
export var section = "news-module--section--g5uoe";
export var maxContent = "news-module--maxContent--mpYRW";
export var content = "news-module--content--v60SD";
export var textTitle = "news-module--textTitle--nitTa";
export var textLists = "news-module--textLists--IMoga";
export var disc = "news-module--disc--AqJ0s";
export var auto = "news-module--auto--u9wuf";
export var decimal = "news-module--decimal--+OTUr";
export var check_mark = "news-module--check_mark--P6DHG";
export var imageBlock = "news-module--imageBlock--TmnyQ";
export var imageText = "news-module--imageText--DgNcO";
export var imageFullWidth = "news-module--imageFullWidth--Rg4vs";
export var imageEvent = "news-module--imageEvent--y9m8O";
export var imageClickable = "news-module--imageClickable--wN1YK";
export var imageClickable_bottom = "news-module--imageClickable_bottom--6ol01";
export var brandButtons = "news-module--brandButtons--Alw1o";
export var text = "news-module--text--5E1Q6";
export var video = "news-module--video--6ohKO";
export var videoSize = "news-module--videoSize--WLsUN";
export var authorWrapper = "news-module--authorWrapper--pKcye";
export var author = "news-module--author--F1DUO";
export var special_offer_image = "news-module--special_offer_image--2F8hV";
export var columnWrapper = "news-module--columnWrapper--805HQ";
export var boxShadow = "news-module--boxShadow--zn30v";
export var youtubeVideo = "news-module--youtubeVideo---NENF";
export var imageWrap = "news-module--imageWrap--wN+Es";
export var textWrap = "news-module--textWrap--sagCF";
export var textWrapCentered = "news-module--textWrapCentered--Gc1WE";
export var margin_top_48 = "news-module--margin_top_48--icjNV";
export var contentWrap = "news-module--contentWrap--+JWDB";
export var productLinksWrapper = "news-module--productLinksWrapper--s8uye";
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import cn from 'classnames'
import { useTranslations } from '../../hooks/use-translations'
import * as s from './NewsSection.module.scss'

interface IProps {
  title?: string
  classTitle?: string
  maxContent?: boolean
  rayContent?: boolean
  dateData?: {
    date: string
    event?: string
  }
}

const NewsSection: React.FC<IProps> = ({
  title,
  classTitle,
  dateData,
  maxContent,
  rayContent,
  children,
}) => {
  const { t } = useTranslations()
  const images = useStaticQuery(imagesQuery)
  return (
    <section
      className={s.section}
      style={{
        marginTop: '64px',
        marginBottom: rayContent ? '206px' : '64px',
      }}
    >
      <div className={cn(s.container, maxContent && s.maxContent)}>
        <div className={s.content}>
          {dateData && (
            <div className={s.dateWrapper}>
              <div className={s.date}>{dateData.date}</div>
              {dateData.event && <div className={s.date}>{dateData.event}</div>}
            </div>
          )}
          {title && <h1 className={cn(s.title, classTitle)}>{title}</h1>}
          {children}
        </div>
      </div>
      {rayContent && (
        <div className={s.trapWrapper}>
          <GatsbyImage
            image={images.trap.childImageSharp.gatsbyImageData}
            className={s.trap}
            objectFit="fill"
            alt={t('ray')}
            title={t('ray')}
          />
        </div>
      )}
    </section>
  )
}

export default NewsSection

const imagesQuery = graphql`
  query {
    trap: file(relativePath: { eq: "trap.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`

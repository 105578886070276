import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import NewsSection from '../../components/NewsSection'
import SEO from '../../components/seo'
import * as s from '../../pages/news.module.scss'

const PriceToolsPro: React.FC = () => {
  return (
    <>
      <SEO
        title="Повышение стоимости на TOOLS PRO"
        description="С 01 января 2023 года мы повышаем стоимость на SIGNAL TOOLS PRO и соответственно на общую лицензию POWERPACK, в которую он входит."
      />
      <NewsSection
        title="Повышение стоимости на TOOLS PRO c 01.01.2023"
        dateData={{ date: '21.12.2022' }}
        rayContent
      >
        <p className={s.text}>
          С 01 января 2023 года мы повышаем стоимость на SIGNAL TOOLS PRO и
          соответственно на общую лицензию POWERPACK, в которую он входит.
        </p>
        <p className={s.text}>
          Повышение не коснется модулей DASHBOARD, INSPECTION и VALUE.
        </p>
        <p className={s.text}>
          Модуль TOOLS делится на 2 версии - Lite и PRO. Lite версия будет
          входить в модуль DASHBOARD и отдельно не продается. Лицензия TOOLS PRO
          будет стоить 54 тыс. руб./год (без НДС) за 1 пользователя. При этом мы
          добавили скидку на количество разовой покупки, аналогичную другим
          модулям.
        </p>
        <p className={s.text}>
          Прайс-лист с 1 января 2023 года будет выглядеть так:
        </p>
        <StaticImage
          width={1160}
          src="../../assets/images/news/2022-12-21-price-tools-pro/image-1.png"
          alt="Прайс-лист модулей"
          title="Прайс-лист модулей"
          placeholder="blurred"
        />
        <h2 className={s.textTitle}>Отличия TOOLS Lite и TOOLS PRO</h2>
        <p className={s.text}>
          TOOLS состоит из 2 плагинов - на Revit и Navisworks, который включают
          в себя следующие функции:
        </p>
        <ul className="flex flex-col items-center has-text-centered">
          <li>
            <p>TOOLS For Revit Lite:</p>
            <StaticImage
              className="mt-3 mb-3"
              src="../../assets/images/news/2022-12-21-price-tools-pro/image-2.png"
              alt="TOOLS For Revit Lite"
              title="TOOLS For Revit Lite"
              placeholder="blurred"
            />
          </li>
          <li>
            <p>TOOLS For Navis Lite:</p>
            <StaticImage
              className="mt-3 mb-3"
              src="../../assets/images/news/2022-12-21-price-tools-pro/image-3.png"
              alt="TOOLS For Navis Lite"
              title="TOOLS For Navis Lite"
              placeholder="blurred"
            />
          </li>
          <li className="flex flex-col">
            <p>TOOLS For Revit PRO:</p>
            <StaticImage
              className="inline-block mt-3 mb-3"
              src="../../assets/images/news/2022-12-21-price-tools-pro/image-4.png"
              alt="TOOLS For Revit PRO"
              title="TOOLS For Revit PRO"
              placeholder="blurred"
            />
            <StaticImage
              className="mb-3"
              src="../../assets/images/news/2022-12-21-price-tools-pro/image-5.png"
              alt="TOOLS For Revit PRO 2"
              title="TOOLS For Revit PRO 2"
              placeholder="blurred"
            />
          </li>
          <li className="flex flex-col">
            <p>TOOLS For Navis PRO:</p>
            <StaticImage
              className="mt-3 mb-3"
              src="../../assets/images/news/2022-12-21-price-tools-pro/image-6.png"
              alt="TOOLS For Navis PRO"
              title="TOOLS For Navis PRO"
              placeholder="blurred"
            />
            <StaticImage
              className="mb-3"
              src="../../assets/images/news/2022-12-21-price-tools-pro/image-7.png"
              alt="TOOLS For Navis PRO 2"
              title="TOOLS For Navis PRO 2"
              placeholder="blurred"
            />
          </li>
        </ul>
        <p className={s.text}>
          TOOLS Lite подходит для отметки выполнения в строительной модели для
          публикации моделей в DASHBOARD и быстрого подсчета объемов по модели.
        </p>
        <p className={s.text}>
          TOOLS PRO позволяет вести полноценную работу с BIM-моделью на
          строительной площадке. Позволяет проверять модели функцией Checker,
          создавать Поисковые наборы по видам работ или по Excel файлу. Делать
          выгрузку объемов работ в Excel.
        </p>
      </NewsSection>
    </>
  )
}

export default PriceToolsPro
